/* All styling used for AWS S3 Bucket content */

.animate-slidein
{ /* TODO:: 05/03/22 #EP | Update name so more explicit and move to animations.css */
  animation-name: slideDown;
  animation-duration: 600ms;
  animation-timing-function: linear;  
}

.bucket-allFolders ol,
.bucket-allFolders ul 
{ 
  text-align: left;
}

div#viewer section
{
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: var(--color_Quaternary);
    padding: 0rem;
    width: 100%;
    margin: auto;
  }
  
.nav_Resources_back
{ /* Back Button to go up a folder */
  font-weight: 400;
}
  
span#btn_Navigation
{ /* Container holding location and back button within folders */
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 0;
  padding: 0;
  height: auto;
  line-height: 2rem;
  padding-left: 1rem;
}
  
span.folder
{ /* Container holding just the folder name used to make it stand out more */
  flex: 2 20%;
  font-size: 2rem;
  margin: .25rem;
  padding: .1rem;
}
  
  
span.folder button.folder_BTN
{ /*TODO:: 05/03/22 #EP || What's this for? */
  width: 90%;
  justify-content: center;
  font-size: 1rem;
  height: 4rem;
}
  
  
#full_Deatils
{ /* Version Details pulled from bucket file folder 
          TODO:: 12/17/2021 #EP || TODO:: Add to all?
*/
  display: none;
  text-align: left;
  padding: 1rem;
  justify-content: space-between;
}
  
.full_Details_Detail
{
  font-weight: bold;
}

#fileHeader
{ /* Title Banner on list of Files  */
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
  border-bottom: 1px solid var(--borders);
}

div.fileResults span
{ /* Container that holds each File result */
  display: flex;
  flex-direction: column;
}

.fileResults span a
{
  border-bottom: 2px solid var(--borders);
}

div.fileResults span a
{ /*-- Styling for all file results from buckets */
  text-align: left;
  color: var(--color_Primary);
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  height: 100%;
  align-items: center;
}

.file
{ /* Individual file result styling */
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.file_checkbox
{ /* Future thinking style for ability to download multiple things */
  display: none;
}

.file a
{ /* Set to 0 so can be animated in */
  opacity: 0;
}

div.fileResults h3
{ /* TODO:: 05/03/22 #EP || Determine what this is for */
  flex: 100%;
}


button.backToFolders 
{ /* TODO:: 05/03/22 #EP || Determine what this is for and if duplicate of .nav_Resources_back */
  display: block;
  border-radius: 1rem;
  border: none;
  /* top: 10rem; */
  margin: 0 1rem;
  height: 3.75rem;
  width: 5rem;
  padding: 0 .15rem 0 0;
  /* position: fixed; */
  text-align: center;
  box-shadow: -.0rem .12rem .1rem rgba(0,0,0,.25); /* offset-x | offset-y | blur-radius | color */
}

.file_End{
  height: 100%;
}

/* hover coloring */
div.fileResults span a:hover
{
  color: var(--color_Accent);
}


/*------------------------------------------------------------------------------
STYLING FOR FILE RESULTS SPACING  */

.fileName
{
  flex: 10%;
}

.fileModified,
.fileSize
{
  flex: 20%;
}
