
@import "animations.css";
@import "aws.css";
@import "chatbot.css";

:root {
  /* Color Theming
      Some exist in just one spot but still here to make easy for quick changes.
  */

  --primary: #236097;
  --primary_chat: #2898ec;
  --secondary: #00ff84;
  --tertiary: #232031;
  --success: #28a745;
  --info: #1f3042;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --white: #fff;
  --black: #000;
  --borders: #e5e7ea;
  
  --body_bg: #FEFEFE;
  /* --body_bg: #f8f8ff; */
  /* --body_bg: #F0F8FF; rgb(240, 248, 255) */
  --body_color: #87909e;
  --link_color: #1b4f7e;
  --link_hover_color: #153f65;

  --header_color: #1e3041;
  
  /* BACKGROUNDS */

  --bg_Primary: rgb(31, 48, 66);
  --footer_Primary: rgb(22, 37, 50);
  
  /* COLORS of POS Nation */
  --color_Primary: rgb(31, 48, 66);
  --color_Secondary: rgb(255, 255, 255);
  --color_Tertiary: rgb(38, 106, 166);
  --color_Quaternary: rgb(240, 248, 255);
  --color_Accent: rgb(0, 255, 132);
  --color_Accent2: rgb(216, 236, 255); /* Beau-Blue - Used for button outlines  */

  --color_shadow_025_black:  rgba(0,0,0,.25);

  --btn_color_Primary: var( --color_Primary);
  --btn_color_Secondary: var(--color_Secondary); 
  --btn_color_Tertiary: var(--color_Tertiary);


  --boxshadow_default: 0 0px 1px rgba(0, 0, 0, 0.50), 0 1px 2px rgba(0, 0, 0, 0.2);

  --shadow_1px_25a: 2px solid var(--color_shadow_025_black);
  
  --bs-h-base: 0 0 .3rem rgba(0,0,0,.25), 0 0 0 .1rem rgba(0,0,0,.1); /* offset-x | offset-y | blur-radius | color */
  --boxshadow_topright_Hover: -.2rem .2rem .5rem rgba(0,0,0,.25); /* offset-x | offset-y | blur-radius | color */
  --boxshadow_topright: 0rem -.05rem .7rem 0rem rgba(0,0,0,.25); /* offset-x | offset-y | blur-radius | color */
  --boxshadow_topright_inset: inset -.2rem .2rem .1rem -.1rem rgba(0,0,0,.25); /* offset-x | offset-y | blur-radius | color */
  --boxshadow_inset:  inset 0rem 0 0.5rem .1rem rgba(0,0,0,.25); /* offset-x | offset-y | blur-radius | color */
  --boxShadow_bottom:  inset 0px -11px 8px -10px rgba(0,0,0,.5); /* offset-x | offset-y | blur-radius | color */
}

* {
  box-sizing: border-box; /* Account for parent element pading. ( Keeps elements from overflowing containers) */
  text-decoration: none;
}

*, ::after, ::before {
  box-sizing: border-box;
}

body {
  font-family: Roboto, sans-serif;
  background-color: var(--body_bg);
  height: 100%;
}

body,h1,h2,h3,h4,h5{
  margin:0;
  padding:0;
  color: var(--header_color);
}

main {
  position: relative;
  height: 100%;
  width: 90%;
  /* align-content: center;
  align-items: center; */
  margin: 4rem auto;

}

h1 {
  font-size: 2rem;
  font-weight: 300;
}
h2 {
  font-size: 1.5rem;
  font-weight: 400;
}


h3 { 
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 1.25rem;
  line-height: 1.8875rem;
  padding-top: 1rem;
}
/* 
h4 { 
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 1.25rem;
  line-height: 1.8875rem;
  padding-top: 1rem;
} */

a {
  -webkit-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  -o-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  color: var(--light);
}


button {
  color: var(--color_Secondary);
  background-color: var(--link_color);
  border-color: var(--btn_color_Tertiary);
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  font-size: 1.0rem;
  line-height: 1.35rem;
  border-radius: 4px;
  height: 3rem;
  text-align: center;
  padding: 0 1.25rem;
  border: 2px solid;
  display: -webkit-inline-box;
  display: inline-flex;
  align-items: center; 
  border-radius: .425rem;

  -webkit-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
    -o-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
    transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
}

button:hover {
  color: #fff;
  background-color: #236097;
  filter: brightness(110%);
  text-decoration: none;
  cursor: pointer;
}


.css-transitions-only-after-page-load * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

/* Disable input element arrows for number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


input {border:0;outline:0;}
input:focus {outline:none!important;}


.disable-select{
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

}

.spacer {
  width: 100%;
  height: 100%;
  padding: 4rem;
}

/*----------------------------------------------------------------------------*/
/*-- START --> STATUS BAR */

.statusbar
{
  /* position: fixed; */
  z-index: 101;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  /* border: 1px solid red; */
  /* top: 3.5rem; */
  background-color: var(--color_Quaternary);
  display: flex;
  /* display: none; */
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  font-size: .75rem;
  box-shadow: var(--boxshadow_inset);
  transition: ease-in-out 200ms;
}

#statusbar-refresh
{ /* Refresh Button within Statusbar */
  cursor: pointer;
}

.status {
  height: 100%;
  margin: auto 0;
}

.status-loading
{
  flex: 1;
  text-shadow: 0rem 0rem .1rem rgba(0,0,0,1);
  color: var(--color_Accent);
}

.status-loading span {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;  
}

/* Once all status content is updated, load in */
.status-loaded{
  /* display: none; */
  flex: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.statusIcon {
  transition: ease-in-out 200ms;
}

.online {
  color: var(--secondary);
};

.offline  {
  color: var(--tertiary);
};
/* -------------------------------------------------------------------------- */
/* START --> NAVIGATION  */
header {
  padding: .5rem 0;
  background-color: var(--bg_Primary);
  line-height: 2rem;
  margin-bottom: 2rem;
}

nav
{  
  background-color: var(--bg_Primary);
  /* spacing around edges and top */
  padding: 0;
  /* max-width: 1920px; */
  line-height: 2rem;
  min-height: 3rem;
  position: fixed;
  /*top: 1.25rem; /* push down to show statusbar */
  z-index: 100;
  width: 100%;

  /* container styling */

  /* font styling */
  font-weight: 500;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 0.75rem;

  /* Display Styling */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
 
  /* justifying */
  -webkit-box-align: left;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.logo {
  /* keep in center of container */
  vertical-align: middle;
  text-align: center;
  border-style: none;
  margin: auto;
  float: center;
  display: flex;
  width: fit-content;
   
  padding: .5rem 0;
  padding-left: .25rem;
  flex: 10%;
  transition: ease-in-out 200ms;
}

.landing-name h1 {
  transition: ease-in-out 200ms;
}

.logo img {
  vertical-align: middle;
  border-style: none;
  filter: brightness(110%);
  vertical-align: middle;
  min-width: 175px;
  max-width: 175px;
  min-height: 39px;
  max-height: 39px;
  border-bottom: 1px solid transparent;
  transition: ease-in-out 200ms;
}


.landing-name h1{
  margin-top: .2rem;
  padding-left: .5rem;
  /* vertical-align: middle; */
  /* text-align: center; */
  color: white;
  height: 100%;
  font-size: 1.5rem;
  font-weight: 300;
  transition: ease-in-out 200ms;
}


.landing-name:hover h1{
  color: var(--color_Accent);
}



/* container holding nav a links */
.nav-item {
  /* line-height: 1.35rem; */
  line-height: 2rem;
  letter-spacing: 1px; /* Makes links easier to read / clean */
  margin: auto 2rem;
  padding: 2rem auto;
  height: 100%;
  /* makes menu fit */
  position: relative;
  transition: ease-in-out 200ms;
  align-items: center;
}

.nav-ele
{ /* The link within the span */
  margin-top: auto;
  /* display: block; */
  /* line-height: 2rem;
  text-align: auto;
  height: 100% !important; */
  transition: ease-in-out 200ms;
  /* border-bottom: 1.5px solid transparent; */
}

nav span a{
  height: 100%;
}
  
.nav-tem.dropdown-menu:hover,
.nav-tem a span:hover,
nav span a:hover{
  -webkit-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  -o-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  transition: all .4s cubic-bezier(.34, 1.45, .7, 1);

  cursor: pointer;
  color: var(--color_Accent);
  
}

.nav-link-other {
  -webkit-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  -o-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  /* transition-timing-function: ease-in-out; */
}

nav span:hover .nav-link-other {
  -webkit-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  -o-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  
  transform: rotate(360deg);
}


.nav-link-arrow {
  -webkit-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  -o-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  /* transition-timing-function: ease-in-out; */
}

nav span:hover .nav-link-arrow {
  -webkit-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  -o-transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  transition: all .4s cubic-bezier(.34, 1.45, .7, 1);
  
  transform: rotate(180deg);
}

/* If navigation has menu, this appears */

nav .dropdown-menu{

  
  /* hidden until hover */
  display: none !important;
  opacity: 0 !important;
  /* Sets to hidden until hovered */
  visibility: hidden !important;
  
  /* position under nav element */
  position: absolute;
  /* always on top */
  z-index: 1000;
  /* Sizing for dropdown menu */
  width: 65%;
  
   /* Sizing for dropdown menu used for different types of browers. */
    /* Right aligned to end of button */
  right: 0;
  /* Left to auto adjust accordingly to right */
  left: auto;
  
  min-width: 20rem;
  /* margin: .125rem 0 0; */
  margin: .525rem 0 0;
  text-align: left;
  text-align: -webkit-match-parent;
  
  border-radius: 5px;

  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(26, 27, 33, .15);

  box-shadow: 0 1rem 3rem rgb(11 11 12 / 18%);
  -webkit-box-shadow: 0 1rem 3rem rgb(11 11 12 / 18%);
  
  overflow: hidden;
  
  border-top: 5px solid #00ff84 !important;
  top: calc(100% - 7px) !important;
  
  
  float: left;
  
  /* slide down effect */
  -webkit-transform: translate(0, -7px);
  -ms-transform: translate(0, -7px);
  transform: translate(0, -7px);
}

nav .dropdown-menu span {
  font-size: .9rem;
  line-height: 1.35rem;
  background-color: transparent;
  /* column */
  display: block;
  padding: .5rem 0 0 .25rem;
  width: 100%;
  border-bottom: 2px solid rgba(135,144,158, .1);
}

nav .dropdown-menu span a, 
nav .dropdown-menu span h3 {
  width: 100%;
  /* Makes sure text is not flat on the edge */
  padding-left: .7rem;
}

nav .dropdown-menu span a {
  color: #1b4f7e;
  text-decoration: none;
}

nav .dropdown-menu span a:hover{
  color:rgb(0, 255, 132);
  font-style: normal;
}

/* Show dropdown menu */
nav span:hover .dropdown-menu {
  
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: auto !important;
  animation: none !important;
}


#nav-settings {
  float: right;
  flex: 10%;
  text-align: right;
}
#nav-settings a{
  width: 40%;
  text-align: center;
  float: right;
}

/*----------------------------------------------------------------------------*/
/*-- START --> MODAL */


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 200; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  /* margin: auto; */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  
  /* Make Modal Appear */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: .4s;
  animation-name: fadeIn;
  animation-duration: .4s;
  
}

/* Modal Content */
.modal-content {
  /* position: fixed; */
  /* bottom: 0; */
  background-color: transparent;
  width: 90%;
  /* top: 0; */
  /* makes fit space available */
  height: auto;
  /* forces to center */
  margin: 10% auto;
  /* top: 5%; */

  /* adds scrolling if needed */
  overflow: auto;

  /* edge styling */
  border-radius: .5rem;
  box-shadow: var(--boxshadow_topright);

  /* Make Modal slide in as appearing appearing annav-eleimations */
  -webkit-animation-name: slideDown;
  -webkit-animation-duration: .4s;
  animation-name: slideDown;
  animation-duration: .4s
}


.modal-header {
  /* padding: 2px 16px; */
  padding: .5rem 0;
  text-align: center;
  background-color: var(--color_Primary);
  color: white;
  display: flex;
  flex-direction: row;
  
  /* Animations */
  /* animation-delay: .2s; */
  -webkit-animation-name: slideDown;
  -webkit-animation-duration: .6s;
  animation-name: slideDown;
  animation-duration: .6s
}
/* title of settings */
.modal-header h2 {
  /* opacity: 0; */
  padding: .25rem;
  margin-left: 2rem;
  color: white;
  flex: 80%;
  text-align: left;
  
}

/* The Close Button */
.close-resources,
.close-settings {
  color: white;
  margin-right: 2rem;
  font-size: 28px;
  font-weight: bold;
  transition: ease-in-out .2s;
}

.close-settings:hover,
.close-settings:focus,
.close-resources:hover,
.close-resources:focus {
  color: var(--color_Accent);
  transition: ease-in-out .2s;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  /* padding: 2px 16px; */
  
  margin: auto;
  padding: 1rem 0;
  background-color: white;
}

.modal-body-section-header{ 
  padding: 0 !important;
  min-width: 200px;
}

.nav.container.resources {
  max-width: none;
  margin: auto;
  /* padding: 0 1rem; */
}

/* ele holding resources*/
.nav.container.resources article{
  width: inherit;
  max-height: none;
  max-width: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .5rem;
  margin: .5rem;
}



/* ele holding button to navigate away */
.nav.container.resources article form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid var(--borders);
  border-radius: .25rem;
  flex: 50%;
  padding: .25rem 1rem;
  
}

.btn_Settings,
.btn_Resources {
  
  /* centers text within button */
  justify-content: center;
  align-content: center;
  align-items: center;
  /* fits container */
  width: 100%;
  height: 6rem;
  
}



.modal-footer {
  /* margin: 1rem; */
  padding: .25rem 0;
  background-color: var(--color_Primary);
  color: white;
  
}

/* The Modal (background) */
.modal1 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content1 {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close1 {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close1:hover,
.close1:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

section.warning {
  /* Make on top of page */
  /* width: 100%;
  position:fixed;
  top: 0;
  left: 0;
  width: 80%; 
  z-index: 99999999; 
  margin: auto;  fits to center of page */ 
  
  /* Fits below navigation */
  margin: .58rem 0 0 0;
  padding: .1rem 0 0 0;
  /* ensure fits full container */
  width: 100%;
  align-content: center;
  /* height: 100%; */
  transition: .2s ease-in-out;
  /* box-shadow: var(--bs-h-base); */
  font-weight: 500;
  
  /* makes taller */
  /* line-height: 2rem; */
  text-align: center;
  font-size: .8rem;
  /* box-shadow: var(--boxshadow_topright); */
  /* opacity: .8; */
}

p.warning {
  z-index: 9999999;
  
  /* Coloring */
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #e1c46e;
  width: 100%;
  /* Spacing around text */
  /* padding: .2rem; */
  /* Removes extra space on bottom */
  margin: 1.5rem 0 0 0;
  margin-block-end: 0;
  /* padding: 0; */
  line-height: 2rem;
}

.warning:after{
  box-sizing: border-box;
}

span.warning:hover {
  transition: .2s ease-in-out;
  
  box-shadow: var(--bs-h-base);
  
}

/*-----------------------------------------------------------*/
/*-- START --> UNIVERSAL ELEMENTS */

.body-container 
{ /* wrapper section that is holding all pages within routes */
  padding-top: 5rem;
}

.page
{ /* div className='page' is used for each page root ele */
  padding-top: 6rem;
}

/* Holds body content */
.content {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  flex: 40%;
  /* width: 100%; */
  
  margin: .5rem auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;
}

.container.banner  {
  width: 100%;
  margin: auto;
  padding: .75rem 0;
  border-radius: 0;
  background-color: var(--color_Quaternary);
}

#banner_Index {
  display: flex;
  flex-direction:  column;
  justify-content: center;
  margin: auto;
  max-width: auto;
  padding: 3rem; /* Used to ensure not covered up by Nav */
  
}

#banner_Index h1 {
  max-width: auto; 
  margin: auto;
  padding: 0;
}


.container article {
  flex: 1;
  width: 100%;
  
}

.resources {
  /* tbd */
}

section.resources{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex: 30%;
  width: 100%;
  /* padding: 0; */
  max-width: 1200px;
}

.resources a {
  
  text-decoration: underline;
  color: black;
  text-align: center;
}

section.resources h2{
  /* padding: 3rem 1rem; */
  padding: 0;
  margin: 0;
  font-size: 3rem;
  flex: 1;
}

.resources article{
  /* transition: .2s ease-in-out; */
  /* flex: 70%; */
  /* padding: 3rem 1rem; */
  /* max-height: 272px;
  min-width: 265px; */
  /* max-width: 100%; */
  
  margin: .3rem;
  padding: 0;
  max-height: inherit;
  height: auto;
  min-width: 350px;
  min-height: 410px;
  width: 100%;
  border: solid rgba(216, 236, 255, .2);
  /* box-shadow: var(--boxshadow_default); */
  transition: ease-in-out 200ms;
}

.container.resources h4 {
  padding: .5rem 0 0;
  vertical-align: center;
  text-align: center;
}

.resources article p {
  margin: .5rem auto;
}

div#viewer section,
.resources article{
  background-color: var(--color_Quaternary);
  border-radius: 5px;
  border: 1px solid #d8ecff;
  
  /* margin: .3rem;
  padding: 0;
  max-height: inherit;
  height: auto;
  min-width: 350px;
  min-height: 410px;
  width: 100%;
  border: solid rgba(216, 236, 255, .2);
  transition: ease-in-out 200ms; */
}

/* .resources article:hover{
  box-shadow: 0 0 .1rem .1rem rgba(0, 0, 0, 0.2);  
} */

.downloads {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  justify-content: space-evenly;
  grid-gap: 1rem;
}

.resource {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .5rem;
  min-width: 30%;
  flex: 30%;
  background-color: var(--color_Quaternary);
  grid-gap: 1rem;
  margin: auto;
  padding: 2rem;
  /* height: 100%; */
  line-height: 2rem;
}

.container label {
  font-size: 1.1rem;
  font-weight: bold;
}

.note {
  width: 60%;
  font-size: .9rem;
}

.container h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
  font-size: 3.5rem;
  margin-bottom: 1.75rem;
  line-height: 4.7250000000000005rem;
  padding: 2rem 1rem;  
}
.container h2{
    position: relative;
    /* padding-bottom: 1.7rem; */
    font-style: normal;
    font-weight: 300;
    text-decoration: none;
    font-size: 2rem;
    margin-bottom: 1.75rem;
    line-height: 4.7250000000000005rem;
    flex: 1;
}



.tooltip
{ /* On hover elements */ 
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: var(--body_bg);
  color: #000;
  text-align: center;
  border-radius: .5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0rem 0 .3rem .05rem rgba(0, 0, 0, 0.50);
  padding: .5rem;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 25%;
  margin-left: -75px;
  opacity: 0;
  /* transition: ; */
  transition-delay: .1s;
  transition: ease-in-out 200ms opacity 0.3s;
  font-size: .7rem;
  font-weight: 400;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--color_Primary) transparent transparent transparent;
  font-size: .5rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


/*-----------------------------------------------------------*/
/* PAX & Network Sections */
/* PAX */

/* PARENT SECTION CONTAINER FOR RESULTS */
#network-section,
#pax-section {
  width: 100%;
  /* height: 100%; */
  margin: 1rem .5rem;
  box-shadow: 0 0 0rem .1rem rgba(0,0,0,.25);
  border-radius: 14px;
  justify-content: center;
  background-color: #fff;

  transition: ease-in 300ms, ease-out 200ms;
  overflow: hidden;
}

#network-section:hover,
#pax-section:hover {
  box-shadow: 0 0 0rem .1rem rgba(0,0,0,.4);
}

#network-section h2,
#pax-section h2 {
  flex: 100%;
  margin: auto;
  padding: 0;
  
}


/* child div holding results */
#network-request-results,
#pax-request-results{
    /* margin: .3rem;
    padding: 0;
    max-height: inherit;
    height: auto;
    min-width: 350px;
    min-height: 410px;
    width: 100%;
    border: solid rgba(216, 236, 255, .2);
    transition: ease-in-out 200ms; */
    
  }
  
  #pax-lookup-submit{
    border-radius: 0 .5rem .5rem 0;
    margin-left: .1rem;
    height: 45px;
    
  }

  /* the serial number input for pax */
  #pax-sn{
    background-color: var(--body_bg);
    box-shadow: inset -.1rem 0 0rem 0rem rgb(0 0 0 / .2);
    padding: 0;
    border: 1px solid rgba(0,0,0,.15);
    height: 45px;
    border-radius: .25rem 0 0 .25rem;
    transition: ease-in-out 200ms;
    text-align: center;
    font-size: 1.1rem;
  }


  #pax-sn:hover,
  #pax-sn:focus{
    background-color: #fff;
    /* border: 1px solid rgba(0,0,0,.25); */
    outline: none;
    box-shadow: inset 0rem 0 0rem .1rem rgb(0 0 0 / .05);
  }
  
  /* child container */
  #network-lookup-results,
  #pax-lookup-results {
    
    border-radius: .25rem;
    border: 1px solid rgba(0,0,0,.25);
    box-shadow: inset 0 0 .1rem .02rem rgba(0,0,0,.25);
    width: 95%;
    padding: 1rem;
    margin: 1rem .5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: justify;
    background-color: #fff;
    filter: brightness(110%);


    /* Add ability to copy-paste this text specifically */
    user-select: text; /* supported by Chrome and Opera */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: text; /* Konqueror HTML */
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
  }
    
  #pax-h4{
    text-align: center;
    width: 100%;
    padding-bottom: .5rem;
  }
  
  /* Each unique result within container */
  #network-lookup-results span,
  #pax-lookup-results span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-overflow: wrap;
    /* overflow: auto; */
    /* line-height: 1.6; */
    /* padding: .25rem; */
    overflow-wrap: break-word;
  }

  .network-lookup-result,
  .pax-lookup-result{
    border-radius: .25rem;
    border-bottom: 1px solid rgba(0,0,0,.25);
    box-shadow: 0 0 .01rem rgba(0,0,0,.25);
    align-items: center; /* Puts reults in center */
    
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;

  }
  
  
  span .label {
    text-align: center;
    flex: 1;
    /* font-weight: bold; */
    font-size: 1rem;
    /* border-bottom: 1px dotted rgba(0,0,0,.25); */
    min-width: 30%;
    height: 2rem;
    margin: auto .5rem;
    border-right: 1px dotted rgba(0,0,0,.25);
  }
  
  code.results{
    flex: 2;
    /* border-radius: .25rem;
    border-bottom: 1px solid rgba(0,0,0,.25);
    box-shadow: 0 0 .01rem rgba(0,0,0,.25); */
    /* border-left: 1px dotted rgba(0,0,0,.25); */
    vertical-align: center;
    height: 100%;
    width: 100%;
    
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    overflow: hidden;
    
  }


/*-----------------------------------------------------------*/
/*-- START --> FOOTER  */

footer {
  padding: .25rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  /* position: sticky; */
  background-color:var(--footer_Primary);
  font-size: .8125rem;
  /* line-height: 1.096875rem; */

  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
}

footer p{
  color: #fff;
  align-items: center !important;
  padding: 0;
  margin: 0;
  margin-top: auto;
  
}

footer p a {
  color: #eef5ff;
  opacity: .7;
  text-decoration: none;
}

/*-- END --> FOOTER  */
/*-----------------------------------------------------------*/
/* START --> MEDIA TYPES */

@media (max-width: 1024px) {
  #banner_Index {
    display: flex;
    flex-direction:  row;
    justify-content: center;
    margin: auto;
    max-width: auto;
  }

  
  #banner_Index h1 {
    max-width: 800px; 
    margin: auto;
  }

  .container.resources h2 {
    padding: 3rem 0;
    
    padding: 0;
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    flex: 100%;
  }

  .container .banner{
    display: none;
  }

  .container .resources{
    width: 90%;
    min-width: 90%;
    /* background: #fff; */
  }
}
@media (max-width: 900px) { 
  
  nav
  { /* Nav Links to go below brand-name or in mobile menu in future */

  }

  .logo-link 
  { /* Prevent link wrapping issues by putting logo on the top in the middle */
    flex: 100%;
    max-height: 2.5rem;
    padding-bottom: 4px;
  }
  .logo{
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .nav-item {
    padding: .25rem 0;
    /* margin-top: 0; */
    line-height: 1.5;
    min-height: fit-content;
  }

  /* page title smaller text */
  #banner_Index h1
  {
    font-size: 2.5rem;
    width: 70%;
  }
  .container.resources article
  {
    
    padding: 1rem 0;
    margin: 1rem 1rem;
  }
}
@media (max-width: 700px) { 
  
  /* page title smaller text */
  #banner_Index  {
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-flow: flex;
  }

  #banner_Index h1 {
    line-height: 1.2;
  }

}
@media (max-width: 500px) { 
  nav{
    /* flex-direction: column;
    align-items: center; */
    
  }

  nav .landing-name h1{
    font-size: 1.2rem;
    font-weight: 400;
    /* padding: 0; */
  }

  .nav-item 
  {
    flex: 100% 1;
    align-items: center;
    text-align: center;
    padding: 2px 0;
    margin: 0;
    line-height: fit-content;
    flex: 100%;
    width: 100%;
  }

  main{
    width: 100%;
  }

  .container.resources article{
    margin: 0;
  }

  .warning {
    font-size: .8rem;
    padding: auto 0;
    line-height: 1.2rem;
  }

}